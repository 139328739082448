import React from 'react';
import { FaHome } from 'react-icons/fa';
import img from '../../Assets/image/singlenews.png'
import { Link } from 'react-router-dom';
import { BsArrowRightCircle } from 'react-icons/bs';

const SingleNews = () => {
    return (
        <div>
            <div className='w-11/12 mx-auto mt-14'>
                <div className='flex justify-between'>
                    <div className=''>
                        <div className='py-6 flex gap-2 items-center'>
                            <FaHome className='text-3xl hidden md:block' />
                            <div className='flex gap-2 text-xl font-semibold'>
                                <h1 className='hidden lg:block'>HOME / News /<span className='text-primary'>BGMEA President emphasizes on turning youth into skilled human resources</span> </h1>
                                <h1 className='text-primary block lg:hidden'>BGMEA President emphasizes on turning youth into skilled human resources</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className='border-2 border-primary
' />

            <div className='w-11/12 md:w-4/5 lg:w-4/6 mx-auto'>
                <div className="card card-compact p-5 rounded-sm bg-base-100 cardShadow mt-8 border">
                    <figure><img src={ img } alt="news" className='rounded-sm' /></figure>
                    <div className="md:card-body py-4 px-0">
                        <p>20 May, 2023</p>
                        <h2 className="card-title text-lg md:text-3xl lg:text-4xl pb-2 lg:pb-6">BGMEA President emphasizes on turning youth into skilled human resources</h2>

                        <p className='text-justify md:text-lg'>BGMEA President Faruque Hassan has laid emphasis on empowering the youth, providing them with the knowledge and quipping them the skills necessary to unleash their potential and contribute to the development of Bangladesh. <br /><br />

                            “Bangladesh has a young a vibrant population, and what we need is to turn them into human resources so that they can accelerate the growth momentum of Bangladesh in the coming years,” he said. <br /><br />

                            He made the remarks while speaking at the grand finale and prize distribution ceremony of BUFT Inter College National Debate (BIND) on the campus of BGMEA University of Fashion and Technology (BUFT) in Dhaka on May 17. <br /><br />

                            Deputy Minister of Education MohibulHasanChowdhury, MP attended the program as chief guest. <br /><br />

                            Chairman of the Board of Trustees of BUFT Md. Shafiul Islam Mohiuddin, MP, Vice-Chancellor of BUFT Prof. Dr. S. M. MahfuzurRahman, and Pro Vice Chancellor of BFT Prof. Dr. Engr. AyubNabi Khan also spoke at the program. <br /><br />
                            Former BGMEA Vice Presidents and BUFT Trustee Board members Md. MoshiulAzam (Shajal) and Mohammed Nasir, among others, were also present at the program. <br /><br />
                            In his address, Faruque Hassan said students need to gather knowledge and skills in line with the global demand and trends in order perform well in their workplaces. <br /><br />
                            “The remarkable technological progress is transforming the way we live and work. Hence, technological and digital skills are no longer an option in this age of technological evolution,” he said. <br /><br />
                            Traditional classroom learning is not enough to compete in this tech-savvy world, he said. <br /><br />
                            He praised the BUFT Debate Club for organizing the debate competition, saying debate is an art that helps to sharpen the skills of critical thinking and express thoughts eloquently. <br /><br />
                            He congratulated all the winners of the debate competition.</p>

                    </div>
                </div>

                <Link to='/allnews' className="card-actions justify-center my-10">
                    <button className="btn btn-wide rounded-sm bg-[#3891A6] text-white gap-2">
                        <span>View All</span>
                        <BsArrowRightCircle />
                    </button>
                </Link>
            </div>

        </div>
    );
};

export default SingleNews;