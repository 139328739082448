import React, { useEffect, useState } from 'react';
import { FaChevronUp, FaHome, FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import detailsImg from '../../Assets/image/details.png'

const AllGarments = () => {
    const [ garments, setGarments ] = useState( [] );

    const [ count, setCount ] = useState( 0 );
    const [ size, setSize ] = useState( 500 );
    const [ page, setPage ] = useState( 1 );

    const pages = Math.ceil( count / size );


    // const randomNumber = Math.floor( Math.random() * 240 ) + 1;

    const url = `https://bgmea.zufaa.tech/bgmea/data/${ page }/12`
    useEffect( () => {
        fetch( url )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                setGarments( data );
                window.scrollTo( 0, 0 )
            } )
    }, [ url ] );


    useEffect( () => {
        fetch( 'https://bgmea.zufaa.tech//bgmea/data/' )
            .then( res => res.json() )
            .then( data => {
                // console.log( data?.length );
                setCount( data?.length );
            } )
    }, [] )

    const handleInput = ( e ) => {
        e.preventDefault()
        // const value = e.target.value;
        const value = e.target.search.value;
        console.log( value );

        fetch( 'https://bgmea.zufaa.tech/bgmea/data/' )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );

                const garments = data.filter( garment => garment?.company_name.toLowerCase().includes( value.toLowerCase() ) );

                // const garments = data?.filter( garment => garment?.company_name?.includes === value )
                console.log( garments );
                setGarments( garments );
            } )


        // console.log( 'object' );
    }

    return (
        <div>
            <div className='w-11/12 mx-auto mt-14'>
                <div className='flex justify-between'>
                    <div className='hidden md:block'>
                        <div className='py-6 flex gap-2 items-center'>
                            <FaHome className='text-3xl' />
                            <div className='flex gap-2 text-xl font-semibold'>
                                <h1>HOME / <span className='text-primary'>Member List</span> </h1>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center items-center'>
                        <h1 className='text-3xl md:text-4xl font-bold text-[#1B3A57] my-3'>All Member Search</h1>
                    </div>
                    <div className='w-40'>
                    </div>
                </div>
            </div>

            <hr className='border-2 border-primary' />

            <img src={ detailsImg } alt="" className='w-full object-cover' />
            <div className='mt-10 w-11/12 mx-auto'>
                <div className='flex gap-6'>
                    <div className='w-1/4 mx-auto'>
                        <h1 className='text-2xl font-semibold px-4'>Filter By</h1>
                        <hr className='my-2' />

                        {/* <div className="collapse">
                            <input type="checkbox" className="" />
                            <div style={ { minHeight: '0px' } } className="collapse-title text-secondary text-xl font-semibold flex justify-between items-center">
                                Product Type
                                <FaChevronUp />
                            </div>
                            <div style={ { paddingBottom: '0px' } } className="collapse-content text-secondary bg-gray-300 rounded-sm flex items-center">
                                <p className='text-white items-center'>hello</p>
                            </div>
                        </div> */}

                        <div className="collapse">
                            <input type="checkbox" />
                            <div className="collapse-title  flex justify-between items-center">
                                <span className='text-xl font-semibold'>Product Type</span>
                                <FaChevronUp />
                            </div>
                            <div style={ { paddingTop: '8px' } } className="collapse-content bg-slate-100 flex flex-col gap-2">
                                <div className='flex gap-2'>
                                    <input type="checkbox" className="w-4" />
                                    <span>Knit</span>
                                </div>
                                <div className='flex gap-2'>
                                    <input type="checkbox" className="w-4" />
                                    <span>Wom en</span>
                                </div>
                                <div className='flex gap-2'>
                                    <input type="checkbox" className="w-4" />
                                    <span>Sweater</span>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className='w-9/12 mx-auto bg-[#FAFAFA] p-7 border rounded-md'>
                        <form onSubmit={ ( e ) => handleInput( e ) } className='flex mb-8'>

                            <input type="text" name='search' className='w-full rounded-l-md px-5 focus:outline-none border border-gray-400 border-r-0' />

                            <label htmlFor="" className='bg-[#3891A6] py-3 px-5 rounded-r-md cursor-pointer'>
                                <input type="submit" value="Submit" className='hidden' />
                                <FaSearch className='text-xl text-white' />
                            </label>
                        </form>

                        {
                            garments &&
                            <div className='flex flex-col gap-5'>
                                {
                                    garments?.map( ( garment, i ) =>
                                        <div key={ i } className='bg-white py-5 px-8 rounded-md grid grid-cols-7 gap-4 items-center'>
                                            <div className='col-span-2'>
                                                <h1 className='text-lg font-bold'>{ garment?.company_name }</h1>
                                                <h2 className='font-semibold'>G-Web REG: { garment?.bgmea_reg_no }</h2>
                                                {/* <p>{ garment?.annual_turnover }</p> */ }
                                            </div>
                                            <div className='col-span-2'>
                                                <h2 className=' font-semibold'> { garment?.factory_type.split( 'Priority' )[ 1 ].split( /\d/ ).filter( part => part !== "" ).join( ", " ) ? garment?.factory_type.split( 'Priority' )[ 1 ].split( /\d/ ).filter( part => part !== "" ).join( ", " ) : 'No Product Type' }</h2>
                                                <h2 className=' font-semibold'> { garment?.principal_exportable_product }</h2>
                                            </div>

                                            <div className='col-span-2'>
                                                <h1 className='font-semibold'>{ garment?.contact_person_name.split( ':' )[ 1 ] }</h1>
                                                <h2 className='font-medium'>Phone: { garment?.contact_person_phone }</h2>
                                            </div>
                                            {/* <div className=''>
                                            <h1 className='font-medium'>{ garment?.email ? garment?.email : 'No Email ID ' }</h1>
                                             <h2 className=''>FAX: { garment?.fax }</h2>
                                        </div> */}


                                            <div className='flex justify-end items-baseline'>
                                                <div className='flex gap-3 items-baseline '>
                                                    <Link to={ `/details/${ garment?.id }` } className='text-primary text-end rounded-sm font-semibold underline'>Details</Link>

                                                </div>
                                            </div>

                                        </div>
                                    )
                                }
                            </div>
                        }

                        <div className='flex justify-center'>
                            <div className="btn-group my-5 gap-2">
                                <button className="btn bg-base-200">Prev</button>
                                {
                                    [ ...Array( pages ).keys() ].map( num =>
                                        // console.log( num )
                                        <button key={ num } onClick={ () => setPage( num + 1 ) } className={ page === num ? 'btn bg-[#86BBD8]' : 'btn bg-base-200' }>{ num + 1 }</button>
                                    )
                                }
                                {/* <button className="btn bg-base-200">.....</button> */ }

                                {/* <button className="btn bg-base-200">1</button>
                                <button className="btn bg-base-200">2</button>
                                <button className="btn bg-base-200">3</button>
                                <button className="btn bg-base-200">4</button>
                                <button className="btn bg-base-200">5</button> */}

                                <select onChange={ ( e ) => setSize( e.target.value ) } className="btn px-0 bg-base-200">
                                    <option defaultValue="300">300</option>
                                    <option value="400">400</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                    <option value="1500">1500</option>
                                </select>

                                <button className="btn bg-base-200">Next</button>

                            </div>

                        </div>



                    </div>
                </div>
            </div>

        </div >
    );
};

export default AllGarments;