import About from "../../Pages/About/About";
import AllGarments from "../../Pages/AllGarments/AllGarments";
import AllNews from "../../Pages/AllNews/AllNews";
import Contact from "../../Pages/Contact/Contact";
import Home from "../../Pages/Home/Home/Home";
import MemberDetails from "../../Pages/MemberDetails/MemberDetails";
import SingleNews from "../../Pages/SingleNews/SingleNews";
import TradeInformation from "../../Pages/TradeInformation/TradeInformation";
import WhyBangladeshDetails from "../../Pages/WhyBangladeshDetails/WhyBangladeshDetails";
import PageNotFound from "../../Shared/PageNotFound/PageNotFound";


const { createBrowserRouter } = require( "react-router-dom" );
const { default: Main } = require( "../../Layouts/Main/Main" );

export const router = createBrowserRouter( [
    {
        path: '/',
        element: <Main></Main>,
        children: ( [
            {
                path: '/',
                element: <Home></Home>
            },
            {
                path: '/about',
                element: <About></About>
            },
            {
                path: '/tradeInfo',
                element: <TradeInformation></TradeInformation>
            },
            {
                path: '/contact',
                element: <Contact></Contact>
            },
            {
                path: '/allnews',
                element: <AllNews></AllNews>
            },
            {
                path: '/singlenews',
                element: <SingleNews></SingleNews>
            },
            {
                path: '/allgarments',
                element: <AllGarments></AllGarments>
            },
            {
                path: '/details/:id',
                element: <MemberDetails></MemberDetails>
            },
            {
                path: '/whybangladesh',
                element: <WhyBangladeshDetails></WhyBangladeshDetails>
            }
        ] )
    },
    {
        path: '*',
        element: <PageNotFound></PageNotFound>
    }
] )