import React from 'react';
import { FaHome } from 'react-icons/fa';

const TradeInformation = () => {
    return (
        <div>
            <div className='w-11/12 mx-auto mt-14'>
                <div className='flex justify-between'>
                    <div className='hidden md:block'>
                        <div className='py-6 flex gap-2 items-center'>
                            <FaHome className='text-3xl' />
                            <div className='flex gap-2 text-xl font-semibold'>
                                <h1>HOME / <span className='text-primary'>Trade Information</span> </h1>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center items-center'>
                        <h1 className='text-3xl md:text-4xl font-bold uppercase text-[#1B3A57] my-3'>Trade Information</h1>
                    </div>
                    <div className='w-40'>
                    </div>
                </div>
            </div>

            <hr className='border-2 border-primary' />

            <div>
                <h1 className='text-5xl font-bold flex justify-center items-center'>Comming Soon....................</h1>
            </div>
        </div>
    );
};

export default TradeInformation;