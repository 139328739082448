import React from "react";
import MovingText from 'react-moving-text'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

import bannerImg from '../../../Assets/image/Rectangle 6.png';
import lineIcon from '../../../Assets/heroIcon/Vector .png'
import './Header.css'

const Header = () => {
    const slides = [ 1, 2, 3, 4 ]


    return (
        <div >
            <Swiper
                spaceBetween={ 30 }
                centeredSlides={ true }
                autoplay={ {
                    delay: 3500,
                    disableOnInteraction: false,
                } }
                effect="slide"
                speed={ 2000 }
                pagination={ {
                    clickable: true,
                } }
                navigation={ true }
                modules={ [ Autoplay, Pagination, Navigation ] }

                className="mySwiper"
            >
                {
                    slides?.map( slide => <SwiperSlide key={ slide }>
                        <div>
                            <div className='showcase h-[300px] md:h-[400px] lg:h-[523px] w-full relative bg-primary object-cover text-white'>
                                <img src={ bannerImg } alt="" className='object-cover h-full w-full' />
                                <div className='overlay h-full'>
                                    <div className='absolute top-1/4 left-5 md:left-10 px-5 md:px-10 w-11/12 md:w-3/5 lg:w-1/2 '>
                                        {/* <MovingText
                                            type="fadeInFromRight"
                                            duration="1400ms"
                                            delay="250ms"
                                            direction="normal"
                                            timing="ease-out"
                                            iteration="1"
                                            fillMode="backwards">


                                        </MovingText> */}
                                        <img src={ lineIcon } alt="" className='absolute w-28 md:w-40 lg:w-64 top-8 md:top-11 lg:top-16' />
                                        <h1 className='text-3xl md:text-5xl lg:text-7xl font-bold leading-tight md:leading-tight lg:leading-tight'>TEXTILES ART HAS VERSATILE FASHION</h1>
                                        <p className='text-xl font-medium '>Garments with “Made in Bangladesh” tag reach people of all ages in more than 150 countries</p>

                                    </div>
                                </div>
                            </div >
                        </div>
                    </SwiperSlide> )
                }

            </Swiper>
        </div>

    );
};

export default Header;
