import React from 'react';
import { FaHome } from 'react-icons/fa';
import allnews from '../../Assets/image/allnews.png'
import { BsArrowRightCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const AllNews = () => {

    const newses = [ 1, 2, 3, 4, 5, 6 ];

    return (
        <div>
            <div className='w-11/12 mx-auto mt-14'>
                <div className='flex justify-between'>
                    <div className='hidden md:block'>
                        <div className='py-6 flex gap-2 items-center'>
                            <FaHome className='text-3xl' />
                            <div className='flex gap-2 text-xl font-semibold'>
                                <h1>HOME / <span className='text-primary'>News</span> </h1>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center items-center'>
                        <h1 className='text-3xl md:text-4xl font-bold uppercase text-[#1B3A57] my-3'>All News</h1>
                    </div>
                    <div className='w-40'>
                    </div>
                </div>
            </div>

            <hr className='border-2 border-primary' />


            {/* card Section-------------- */ }
            <div className='w-11/12 mx-auto mt-10'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
                    {
                        newses?.map( news =>
                            <div data-aos="fade-up" data-aos-duration="2000" key={ news } className="card card-compact rounded-md bg-base-100 shadow-xl">
                                <figure><img src={ allnews } alt="news" className='w-full object-cover' /></figure>
                                <div className="card-body">
                                    <h2 className="card-title">BGMEA President emphasizes on turning youth into skilled human resources</h2>
                                    <p>20 May, 2023</p>
                                    <p>BGMEA President Faruque Hassan has laid emphasis on empowering the youth, providing them with the...</p>
                                    <Link to='/singlenews' className="card-actions justify-start">
                                        <button className="btn btn-sm rounded-sm bg-primary text-white gap-2">
                                            <span>Read More</span>
                                            <BsArrowRightCircle />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default AllNews;