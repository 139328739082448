import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const TopGarments = () => {
    const [ garments, setGarments ] = useState( [] )


    const randomNumber = Math.floor( Math.random() * 240 ) + 1;

    const url = `https://bgmea.zufaa.tech/bgmea/data/${ randomNumber }/12`
    useEffect( () => {
        fetch( url )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                setGarments( data );
            } )
    }, [] );



    return (
        <div className='w-11/12 mx-auto mb-28'>

            <div data-aos="fade-up" data-aos-duration="1000" className='bg-primary px-10 py-2 w-52 mx-auto rounded-sm'>
                <h1 className='text-xl font-bold uppercase text-center text-white'>Top Garments</h1>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000" className='cardShadow p-5 md:p-10 rounded-md'>
                <Link to='/allgarments' className='text-primary text-xl font-semibold text-right flex justify-end underline mb-8 hidden lg:block'>See All</Link>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
                    {
                        garments?.map( ( garment, i ) =>
                            <div
                                key={ i }
                                data-aos="fade-up" data-aos-duration="1000"
                                className='cardShadow px-5 py-2 rounded-md'>
                                <h1 className='text-xl font-bold mt-2'>{ garment?.company_name.length > 35 ? garment?.company_name.slice( 0, 35 ) + '....' : garment?.company_name }</h1>
                                <h1 className='font-semibold mb-2'>
                                    Type: <span className='text-[#6F7173] font-sans font-bold'>{ garment?.factory_type.split( 'Priority' )[ 1 ].split( /\d/ ).filter( part => part !== "" ).join( ", " ) }</span></h1>
                                <p className='text-[#6F7173] font-sans'>{ garment?.principal_exportable_product }</p>


                                <div className='flex justify-end items-baseline'>
                                    <div className='flex gap-3 items-baseline '>
                                        <Link to={ `/details/${ garment?.id }` } className='text-primary text-end rounded-sm font-semibold underline'>Details</Link>

                                        <div className="w-5 overflow-hidden inline-block">
                                            <div className=" h-7 bg-primary rotate-45 transform origin-bottom-left"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <Link to='/allgarments' className='text-primary text-xl font-semibold text-right flex justify-end underline mt-8 md:block lg:hidden'>See All</Link>
            </div>
        </div>
    );
};

export default TopGarments;