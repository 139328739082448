import React from 'react';
import { FaFacebookF, FaInstagram, FaMapMarkerAlt, FaPhoneAlt, FaTwitter, FaYoutube } from "react-icons/fa";
import yarnfooter from '../../Assets/heroIcon/yarnfooter.png'

const Footer = () => {
    return (
        <footer data-aos="fade-up" data-aos-duration="2000"
            className="footer footer-center pt-10 pb-5 bg-secondary text-base-100 mt-10 gap-5 relative">
            <img src={ yarnfooter } alt="" className='absolute left-0 bottom-32 md:bottom-28 w-2/5 md:w-1/5' />
            <div>
                <h1 className='text-7xl lg:text-6xl font-bold font text-white'>G<span className='font-bold text-primary'>Web</span></h1>
                <div className='flex flex-col md:flex-row gap-10 md:gap-24 my-5'>
                    <div data-aos="fade-up" data-aos-duration="2000">
                        <div className='flex items-center gap-3 text-3xl font-bold pb-3 justify-center'>
                            <div className='bg-primary p-2 rounded-md hidden md:block'>
                                <FaPhoneAlt className='text-lg' />
                            </div>
                            <span className='text-center'>CONTACT US</span>
                        </div>
                        <p className='text-lg'>sales@exampleinfo.com</p>
                        <p className='text-2xl font-bold text-primary'>+ 123 456 7890</p>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="2000">
                        <div className='flex items-center gap-3 text-3xl font-bold pb-3 justify-center'>
                            <div className='bg-primary p-2 rounded-md hidden md:block'>
                                <FaMapMarkerAlt className='text-lg' />
                            </div>
                            <span className='text-center'>ADDRESS</span>
                        </div>
                        <p className='text-lg'>BGMEA Complex, House # <br /> 7/7A,Sector # 17, Block # H-1, 1230</p>
                    </div>

                </div>
            </div>
            <div data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-anchor-placement="top-bottom">
                <div className="grid grid-flow-col gap-5 text-2xl">
                    <div className='p-3 bg-[#1B3A57] rounded-full cursor-pointer hover:text-blue-500 duration-500'>
                        <FaFacebookF />
                    </div>
                    <div className='p-3 bg-[#1B3A57] rounded-full cursor-pointer hover:text-red-500 duration-500'>
                        <FaInstagram />
                    </div>
                    <div className='p-3 bg-[#1B3A57] rounded-full cursor-pointer hover:text-sky-500 duration-500'>
                        <FaTwitter />
                    </div>
                    <div className='p-3 bg-[#1B3A57] rounded-full cursor-pointer hover:text-red-700 duration-500'>
                        <FaYoutube />
                    </div>
                </div>
            </div>
            <hr className='w-full' />
            <h1>Copyright © 2023, The Bangladesh Garment Manufacturers and Exporters Association. <br />
                Design & Developed by Zufaa Inc.</h1>
        </footer>
    );
};

export default Footer;