import React from 'react';
import img1 from '../../../Assets/image/image 1.png'
import img2 from '../../../Assets/image/Group 39.png'

const Strength = () => {
    return (
        <div className='bg-[#F0F4F5] pt-12 pb-20 mb-16 md:mb-20'>
            <div data-aos="fade-up" data-aos-duration="1000" className='w-11/12 mx-auto '>
                <h1 className='text-4xl font-bold uppercase text-secondary text-center'>Our Strength</h1>
                <p className='w-11/12 md:w-3/4 lg:w-1/2 mx-auto text-center my-4'>Bangladesh is a trusted hub of apparel sourcing across the globe. More than 150 countries import ready-made garments from Bangladesh for:</p>
                <div className='w-11/12 lg:w-4/5 mx-auto mt-10 md:mt-16 flex flex-col gap-11 md:gap-0 md:flex-row justify-between'>
                    <div className='w-full md:w-1/2 mx-auto relative'>
                        <img data-aos="fade-up" data-aos-duration="1000" src={ img1 } alt="" className='shadow-sm h-5/6 lg:h-full rounded-lg object-cover' />

                        <div className='bg-white p-3 lg:p-5 rounded-md flex gap-3 items-center md:gap-6 w-48 md:w-56 lg:w-80 cardShadow absolute -bottom-6 md:bottom-24 lg:bottom-9 right-16 md:-right-5 lg:-right-5'>

                            <h1 className='text-3xl md:text-4xl lg:text-7xl font-bold text-primary'>37+</h1>
                            <p className='md:text-lg lg:text-xl font-bold'>Year Experience in Fabiflex</p>
                        </div>

                    </div>
                    <div className='w-full md:w-1/2 mx-auto'>
                        <img data-aos="fade-up" data-aos-duration="1000" src={ img2 } alt="" className=' rounded-lg  object-cover' />
                    </div>
                </div>

                {/* <div className='w-4/5 mx-auto mt-16 flex justify-between'>
                    <img data-aos="fade-up" data-aos-duration="1000" src={ img1 } alt="" className='shadow-sm rounded-lg w-[405px] h-[450px]' />
                    <img data-aos="fade-up" data-aos-duration="1000" src={ img2 } alt="" className=' rounded-lg w-[450px] h-[450px] object-cover' />
                </div> */}

            </div>
        </div>
    );
};

export default Strength;