import React from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from "react-icons/fa";

const Navber = () => {

    const menuItem = <>
        <Link to='/'>Home</Link>
        <Link to='/whybangladesh'>Why Bangladesh</Link>
        <Link to='/allnews'>News</Link>
        <Link to='/tradeInfo'>Trade Information</Link>
        <Link to='/allgarments'>Members</Link>
        <Link to='/about'>About</Link>
        <Link to='/contact'>Contact</Link>
    </>
    return (
        <div className='bg-white shadow-lg fixed top-0 z-50 w-full'>
            <div className='w-11/12 mx-auto py-2 flex items-center justify-between'>
                <Link to='/'>
                    <h1 className='text-4xl font-bold font text-black'>Garments<span className='text-4xl font-bold text-primary'>Hub</span></h1>
                </Link>
                <div className='hidden lg:block'>
                    <div className='font-sans flex gap-8 font-semibold'>
                        { menuItem }
                    </div>
                </div>

                <div className="dropdown -right-10 pr-10 dropdown-end block lg:hidden">
                    <label tabIndex={ 0 } className="rounded-btn cursor-pointer"> <FaBars className='text-2xl font-bold' /></label>

                    <ul data-aos="fade-left"
                        data-aos-anchor="#example-anchor"
                        data-aos-offset="500"
                        data-aos-duration="500" tabIndex={ 0 } className="menu dropdown-content -right-16 -top-4 font-semibold gap-3 px-5 py-4 shadow bg-base-100 rounded-sm w-52 h-screen">
                        { menuItem }
                    </ul>
                </div>

            </div>

        </div>
    );
};

export default Navber;