import React from 'react';
import NotPage from '../../Assets/image/404 page.png'

const PageNotFound = () => {
    return (
        <div className='flex justify-center items-center'>
            <img src={ NotPage } alt="" />
        </div>
    );
};

export default PageNotFound;