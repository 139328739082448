import React from 'react';
import { Link } from 'react-router-dom';
import news from '../../../Assets/image/news.png';
import machine from '../../../Assets/image/machine.png'
import { BsArrowRightCircle } from "react-icons/bs";

import './News.css'

const News = () => {

    const arr = [ 1, 2, 3 ];


    return (
        <div className='w-11/12 mx-auto mb-28 '>
            <div className='flex justify-between items-end mb-8 md:mb-11'>
                <div data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1000" >
                    <div className='flex gap-2 items-baseline'>
                        <h1 className='text-base md:text-lg lg:text-xl font-semibold'>DIRECTLY FROM THE NEWS</h1>
                        <div className="w-3 md:w-4 overflow-hidden inline-block">
                            <div className="h-5 md:h-7 bg-primary rotate-45 transform origin-bottom-left"></div>
                        </div>
                    </div>
                    <h1 className='text-lg md:text-2xl lg:text-4xl font-bold'>WE BELIEVE IN COUNTING NUMBERS</h1>


                </div>

                <Link to='/allnews'
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1000" className='px-2 md:px-5 py-1 bg-primary rounded-sm text-sm md:text-lg font-medium text-white hidden lg:block'>More News +
                </Link>
                {/* <Link data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1000" className='px-2 md:px-5 py-1 bg-primary rounded-sm text-sm md:text-lg font-medium text-white'>More News +
                </Link> */}
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 '>
                <div className='cardShadow px-5 rounded-sm' data-aos="zoom-in" data-aos-duration="1000">
                    {
                        arr?.map( ( news, i ) =>
                            <div key={ news } data-aos="zoom-in" data-aos-duration="1200">
                                <div className='flex gap-3 my-5'>
                                    <img src={ machine } alt="" />
                                    <div>
                                        <h1 className='text-lg font-medium text-[#6F7173]'>20 May, 2023</h1>
                                        <p className='text-lg font-medium leading-5'>Can textiles lead during the pandemic?</p>
                                    </div>
                                </div>

                                <hr />
                            </div>
                        )
                    }

                </div>

                <div className='relative showbg rounded-sm' data-aos="zoom-in" data-aos-duration="1200">
                    <img src={ news } alt="" className='object-cover h-full w-full rounded-sm' />
                    <div className='newscard h-full p-5 text-white rounded-sm'>

                        <h1 className='text-base md:text-lg lg:text-xl font-semibold mt-[240px] lg:mt-[280px]'>BGMEA President emphasizes on turning youth into skilled human resources</h1>
                        <hr className='border border-b border-dashed my-3' />
                        <div className='flex justify-between items-center text-lg font-normal'>
                            <h1>20 May, 2023</h1>
                            <Link className='flex items-center gap-2'>Read More <BsArrowRightCircle /> </Link>
                        </div>

                    </div>
                </div>

                <div className='relative showbg rounded-sm' data-aos="zoom-in" data-aos-duration="1500">
                    <img src={ news } alt="" className='object-cover h-full w-full rounded-sm' />
                    <div className='newscard h-full p-5 text-white rounded-sm'>

                        <h1 className='text-base md:text-lg lg:text-xl font-semibold mt-[240px] lg:mt-[280px]'>BGMEA President emphasizes on turning youth into skilled human resources</h1>
                        <hr className='border border-b border-dashed my-3' />
                        <div className='flex justify-between items-center text-lg font-normal'>
                            <h1>20 May, 2023</h1>
                            <Link className='flex items-center gap-2'>Read More <BsArrowRightCircle /> </Link>
                        </div>

                    </div>
                </div>


            </div>

            {/* For Mobile view */ }
            <div className='mt-5 flex justify-end sm:block md:hidden'>
                <Link to='/allnews'
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1000" className='px-2 md:px-4 py-2 bg-primary w-36 rounded-sm text-sm md:text-lg font-medium text-white text-center '>More News +
                </Link>
            </div>

        </div>
    );
};

export default News;