import React from 'react';
import Header from '../Header/Header';
import WhyBangladesh from '../WhyBangladesh/WhyBangladesh';
import TopGarments from '../TopGarments/TopGarments';
import Strength from '../Strength/Strength';
import News from '../News/News';

const Home = () => {
    return (
        <div className='mb-5 mt-14'>
            <Header></Header>
            <WhyBangladesh></WhyBangladesh>
            <TopGarments></TopGarments>
            <Strength></Strength>
            <News></News>
        </div>
    );
};

export default Home;