import React, { useEffect } from 'react';
import whyBangladesh from '../../Assets/image/whyBangladesh.png';
import bangladesh from '../../Assets/image/bangladesh.png';
import bangladesh1 from '../../Assets/image/bangladesh1.png';

import Exp from '../../Assets/heroIcon/image 2.png';
import Complaint from '../../Assets/heroIcon/image 5.png';
import Competitive from '../../Assets/heroIcon/image 6.png';
import hub from '../../Assets/heroIcon/image 15.png';
import facilities from '../../Assets/heroIcon/image 16.png';
import factory from '../../Assets/heroIcon/image 17.png';
import img1 from '../../Assets/image/Rectangle 80.png'
import img2 from '../../Assets/image/Rectangle 81.png'
import img3 from '../../Assets/image/Rectangle 82.png'

const WhyBangladeshDetails = () => {

    useEffect( () => {
        window.scrollTo( 0, 0 )
    }, [] )
    return (
        <div className='mt-14'>
            <div className='showcase w-full relative bg-primary object-cover text-white'>
                <img src={ whyBangladesh } alt="" className='object-cover h-full w-full' />
                <div className='overlay h-full flex justify-center items-center'>
                    <h1 className='md:text-3xl lg:text-6xl font-bold'>About Garment Industry of Bangladesh</h1>
                </div>
            </div >
            <div className='w-full relative py-16 mt-5 overflow-hidden'>
                <h1 className='text-5xl md:text-6xl lg:text-8xl font-bold uppercase font-sans text-right absolute top-0 -right-20 md:-right-28 lg:-right-52 overflow-hidden text-gray-300'>Bangladesh</h1>
            </div>
            <div className='w-5/6 mx-auto'>
                <div className='lg:flex justify-between'>
                    <div className='w-full lg:w-1/2 h-full'>
                        <img src={ bangladesh } alt="" className='rounded-md h-48 md:h-96 lg:h-full w-full lg:w-11/12 object-cover' />
                    </div>
                    <p className='text-sm lg:text-base font-sans text-justify w-full lg:w-1/2 mt-5 lg:mt-0'>The readymade garments industry acts as a catalyst for the development of Bangladesh. The "Made in Bangladesh" tag has also brought glory for the country, making it a prestigious brand across the globe. Bangladesh, which was once termed by cynics a "bottomless basket" has now become a "basket full of wonders." The country with its limited resources has been maintaining 6% annual average GDP growth rate and has brought about remarkable social and human development.
                        <br />
                        After the independence in 1971, Bangladesh was one of poorest countries in the world. No major industries were developed in Bangladesh, when it was known as East Pakistan, due to discriminatory attitude and policies of the government of the then West Pakistan. So, rebuilding the war-ravaged country with limited resources appeared to be the biggest challenge. <br />
                        The industry that has been making crucial contribution to rebuilding the country and its economy is none other than the readymade garment (RMG) industry which is now the single biggest export earner for Bangladesh. The sector accounts for 83% of total export earnings of the country.
                    </p>
                </div>

                <div className='flex lg:flex-row flex-col-reverse md:flex-col-reverse gap-5 lg:gap-16 justify-between mt-10'>
                    <div className='font-sans text-justify w-full lg:w-1/2 flex'>
                        <p className='text-sm lg:text-base font-sans'>
                            When our only major export earner "the jute industry" started losing its golden days, it is the RMG sector that replaced it, and then, to overtake it. <br /> <br />
                            The apparel industry of Bangladesh started its journey in the 1980s and has come to the position it is in today. The late NuroolQuader Khan was the pioneer of the readymade garment industry in Bangladesh. He had a vision of how to transform the country. In 1978, he sent 130 trainees to South Korea where they learned how to produce readymade garments.
                        </p>
                    </div>
                    <div className='w-full lg:w-1/2 flex justify-end md:-mt-0 lg:-mt-24'>
                        <img src={ bangladesh1 } alt="" className='rounded-md w-full h-80 object-cover' />
                    </div>
                </div>
            </div>
            <div className='w-11/12 mx-auto mt-10'>
                <h1 className='text-xl md:text-2xl lg:text-3xl font-bold uppercase font-sans text-gray-300'>Why Bangladesh</h1>

                <div data-aos="fade-up" data-aos-duration="1500"
                    data-aos-anchor-placement="top-bottom"
                    className='w-11/12 lg:w-4/5 mx-auto mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 md:relative text-center'>

                    <div data-aos="fade-up" data-aos-duration="1500"
                        data-aos-anchor-placement="top-bottom" className='cardShadow rounded-md py-7 px-6'>
                        <img src={ Exp } alt="" className='mx-auto' />
                        <h1 className='py-4 text-xl font-semibold'>EXPERIENCE</h1>
                        <p>35+ years of experience in apparel manufacturing, With 4000+ factories Bangladesh is serving all major global fashion brands.</p>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1500"
                        data-aos-anchor-placement="top-bottom" className='cardShadow rounded-md py-7 px-6'>
                        <img src={ Complaint } alt="" className='mx-auto' />
                        <h1 className='py-4 text-xl font-semibold'>COMPLIANT</h1>
                        <p>All factories maintain international safety and compliance, and assessed in accordance with BSCI, WRAP, SMETA, ICS, Higg index, GTW, RTM, Join Life, ZDHC, Blue Sign, ISO, OCS, GOTS, Oekotex.</p>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1500"
                        data-aos-anchor-placement="top-bottom" className='cardShadow rounded-md py-7 px-6'>
                        <img src={ Competitive } alt="" className='mx-auto' />
                        <h1 className='py-4 text-xl font-semibold'>COMPETITIVE</h1>
                        <p>Duty-free Market Access to EU, Experienced Labour Force, and International Quality.</p>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1500"
                        data-aos-anchor-placement="top-bottom" className='cardShadow rounded-md py-7 px-6'>
                        <img src={ hub } alt="" className='mx-auto' />
                        <h1 className='py-4 text-xl font-semibold'>TRUSTED SOURCING HUB</h1>
                        <p>More than 150 countries import apparel from Bangladesh.</p>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1500"
                        data-aos-anchor-placement="top-bottom" className='cardShadow rounded-md py-7 px-6'>
                        <img src={ facilities } alt="" className='mx-auto' />
                        <h1 className='py-4 text-xl font-semibold'>PRODUCTION FACILITIES</h1>
                        <p>Factories equipped with latest machinery and technologies to meet the demand of latest fashion trends.</p>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1500"
                        data-aos-anchor-placement="top-bottom" className='cardShadow rounded-md py-7 px-6'>
                        <img src={ factory } alt="" className='mx-auto' />
                        <h1 className='py-4 text-xl font-semibold'>GREEN FACTORIES</h1>
                        <p>Home to world's highest number of Green Factories. These factories use energy-efficient and environment-friendly technology and practices that save water, energy and minimize pollution.</p>
                    </div>

                </div>

                <div className='grid grid-cols-2 my-16 gap-3'>
                    <img src={ img1 } alt="" className='row-span-2 w-full object-cover rounded-sm' />
                    <img src={ img2 } alt="" className='h-full object-cover rounded-sm' />
                    <img src={ img3 } alt="" className='h-full object-cover rounded-sm' />
                </div>
            </div>
        </div>
    );
};

export default WhyBangladeshDetails;