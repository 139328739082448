import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import detailsImg from '../../Assets/image/details.png';
import { FaAngleDoubleLeft, FaHome } from 'react-icons/fa';

const MemberDetails = () => {
    const [ garment, setGarment ] = useState( '' )
    const { id } = useParams();

    const [ position, setPosition ] = useState( '' );
    const [ name, setName ] = useState( '' );
    const [ phone, setPhone ] = useState( '' );
    const [ email, setEmail ] = useState( '' )
    const navigate = useNavigate();
    // console.log( id );

    const url = `https://bgmea.zufaa.tech/bgmea/data/${ id }`
    useEffect( () => {
        // window.scrollTo( 0, 0 );
        fetch( url )
            .then( res => res.json() )
            .then( data => {

                if ( data?.id ) {


                    // Get Position------------------
                    const dPosition = data?.director_information?.split( '|' )[ 0 ]?.split( ':' )[ 1 ];
                    setPosition( dPosition );

                    // Get Name------------------
                    const dName = data?.director_information?.split( '|' )[ 1 ]?.split( ':' )[ 1 ];
                    setName( dName );

                    // Get Phone Number--------------
                    const dPhone = data?.director_information?.split( '|' )[ 2 ]?.split( ':' )[ 1 ];
                    setPhone( dPhone );

                    // Get email Id-------------------------
                    const dEmail = data?.director_information?.split( '|' )[ 2 ]?.split( ':' )[ 1 ];
                    setEmail( dEmail );

                    // console.log( data );
                    setGarment( data )
                }
                else {
                    navigate( '/NoDataFound' )
                }
                // console.log( data );

            } )
    }, [ url, navigate ] );

    const { bgmea_reg_no,
        epb_reg_no,
        mailing_address,
        factory_address,
        website,
        date_of_establishment,
        factory_type,
        no_of_employees,
        no_of_machines,
        production_capacity,
        certifications,
        principal_exportable_product,
        annual_turnover
    } = garment;



    return (
        <div>
            <div className='w-11/12 mx-auto mt-14' >
                <div className='flex justify-between'>
                    <div className='hidden md:block'>
                        <div className='py-6 flex gap-2 items-center'>
                            <FaHome className='text-3xl' />
                            <div className='flex gap-2 text-xl font-semibold'>
                                <h1>HOME / <span className='text-primary'>Member Details</span> </h1>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center items-center'>
                        <h1 className='text-3xl md:text-4xl font-bold  text-[#1B3A57] my-3'>Member Details</h1>
                    </div>
                    <div className='w-40'>
                    </div>
                </div>
            </div >

            <hr className='border-2 border-primary
' />

            <div className='mt-10'>
                <div className='w-full mx-2 md:w-11/12 md:mx-auto cardShadow p-2 md:p-5 border border-1 rounded-md'>

                    {/* header section----------------- */ }

                    <div>
                        <div className='showcase'>
                            <img src={ detailsImg } alt="" className='w-full object-cover' />
                            <div className='overlay'></div>
                        </div>
                        <div className='w-full bg-[#0A1F35] rounded-sm pl-4 pr-2 py-1 md:py-2 my-5 flex justify-between items-center font-semibold text-white'>
                            <Link to='/allgarments'>
                                <button className='flex gap-1 items-center bg-[#1B3A57] px-2 py-2 rounded-sm '>
                                    <FaAngleDoubleLeft />
                                    Back
                                </button>
                            </Link>
                            <h1 className='text-base md:text-2xl font-semibold '>{ garment?.company_name }</h1>
                            <div className='w-10 hidden lg:block'></div>
                        </div>
                    </div>

                    {/* Details Section------------------------- */ }
                    <div className='flex flex-col gap-5'>


                        {/* Company Information-------------------
                        ------------------------------------------ */ }
                        <div className='cardShadow p-2 md:p-5 rounded-sm'>
                            <h1 className='text-primary text-xl font-semibold'>Company Information</h1>
                            <hr className='mt-2 mb-5' />
                            {/* text-[#3891A6] */ }
                            <div>

                                {/* BGMEA Reg section----------------------- */ }
                                <div className="flex w-full bg-[#EFEFEF]">
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-2/6 md:w-1/4 lg:w-1/6 mr-0">
                                        <h1 className='text-base md:text-lg font-semibold text-[#1B3A57]'>BGMEA Reg. No:</h1>
                                    </div>
                                    <div className="divider divider-horizontal ml-0 mr-0"></div>
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-10/12 ml-0">
                                        <p className='font-sans'>{ bgmea_reg_no }</p>
                                    </div>
                                </div>

                                {/* EPB Reg Section-------------------------------- */ }
                                <div className="flex w-full ">
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-2/6 md:w-1/4 lg:w-1/6 mr-0">
                                        <h1 className='text-base md:text-lg font-semibold text-[#1B3A57]'>EPB Reg No:</h1>
                                    </div>
                                    <div className="divider divider-horizontal ml-0 mr-0"></div>
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-10/12 ml-0">
                                        <p className='font-sans'>{ epb_reg_no }</p>
                                    </div>
                                </div>

                                {/* Director Information-------------------------- */ }
                                <div className="flex w-full bg-[#EFEFEF]">
                                    <div className="grid flex-grow card rounded-sm px-2 md:pl-4 pr-2 py-2 w-2/6 md:w-1/4 lg:w-1/6 mr-0">
                                        <h1 className='text-base md:text-lg font-semibold text-[#1B3A57]'>Director Informaiton</h1>
                                    </div>

                                    <div className="divider divider-horizontal ml-0 mr-0"></div>

                                    <div className="flex flex-row justify-between gap-2 md:grid md:flex-grow card rounded-sm px-2 md:pl-4 pr-2 py-2 w-10/12 ml-0">
                                        <div className='flex flex-col md:grid grid-cols-4 gap-5 text-base md:text-xl font-semibold'>
                                            <h1>Position</h1>
                                            <h1>Name</h1>
                                            <h1>Mobile No.</h1>
                                            <h1>Email</h1>
                                        </div>
                                        <hr className='border border-gray-300 md:block h-full' />
                                        <div className='flex flex-col md:grid md:grid-cols-4 gap-6 md:gap-5 text-sm md:text-base '>
                                            <p className='font-sans'>{ position }</p>
                                            <p className='font-sans'>{ name }</p>
                                            <p className='font-sans'>{ phone }</p>
                                            <p className='font-sans'>{ email }</p>
                                        </div>

                                    </div>
                                </div>

                                {/* Mailling Address section----------------------------- */ }
                                <div className="flex w-full ">
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-2/6 md:w-1/4 lg:w-1/6 mr-0">
                                        <h1 className='text-base md:text-lg font-semibold text-[#1B3A57]'>Mailling Address:</h1>
                                    </div>

                                    <div className="divider divider-horizontal ml-0 mr-0"></div>

                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-10/12 ml-0">
                                        <p className='font-sans'>{ mailing_address }</p>
                                    </div>
                                </div>

                                {/* Factory Address---------------------------------- */ }

                                <div className="flex w-full bg-[#EFEFEF] ">
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-2/6 md:w-1/4 lg:w-1/6 mr-0">
                                        <h1 className='text-base md:text-lg font-semibold text-[#1B3A57]'>Factory Address:</h1>
                                    </div>

                                    <div className="divider divider-horizontal ml-0 mr-0"></div>

                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-10/12 ml-0 ps-1">
                                        <p className='font-sans'>{ factory_address }</p>
                                    </div>
                                </div>
                            </div>

                        </div>



                        {/* Additional Information-----------------------
                        ------------------------------------------------- */ }

                        <div className='cardShadow p-5 rounded-sm'>
                            <h1 className='text-primary text-xl font-semibold'>Additional Information</h1>
                            <hr className='mt-2 mb-5' />

                            <div>

                                {/* Website Address section----------------------- */ }
                                {
                                    website &&
                                    <div className="flex w-full ">
                                        <div className="grid flex-grow card bg-[#EFEFEF] rounded-sm pl-4 pr-2 py-2 w-2/6 md:w-1/4 lg:w-1/6 mr-0">
                                            <h1 className='text-lg font-semibold text-[#1B3A57]'>Website:</h1>
                                        </div>
                                        <div className="divider divider-horizontal ml-0 mr-0"></div>
                                        <div className="grid flex-grow card bg-[#EFEFEF] rounded-sm pl-4 pr-2 py-2 w-10/12 ml-0">
                                            {/* <p className='font-sans'>{ website }</p> */ }
                                            <a href={ website } target="blank" className='font-sans hover:text-blue-400 duration-500'>{ website }</a>
                                        </div>
                                    </div>
                                }



                                {/* Date of Establishment Section-------------------------------- */ }
                                <div className="flex w-full ">
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-2/6 md:w-1/4 lg:w-1/6 mr-0">
                                        {/* <h1 className='text-lg font-semibold text-[#1B3A57]'>Establishment Date:</h1> */ }
                                        <h1 className='text-sm md:text-lg font-semibold text-[#1B3A57]'>Establishment Date:</h1>
                                    </div>
                                    <div className="divider divider-horizontal ml-0 mr-0"></div>
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-10/12 ml-0">
                                        <p className='font-sans'>{ date_of_establishment }</p>
                                    </div>
                                </div>

                                {/* Factory Type Information-------------------------- */ }
                                <div className="flex w-full bg-[#EFEFEF]">
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-2/6 md:w-1/4 lg:w-1/6 mr-0">
                                        <h1 className='text-sm md:text-lg font-semibold text-[#1B3A57]'>Factory Type:</h1>
                                    </div>

                                    <div className="divider divider-horizontal ml-5 md:ml-0 mr-0 "></div>

                                    <div className="grid flex-grow card rounded-sm px-2 py-2 w-10/12 ml-0">
                                        <div className='grid grid-cols-2 gap-10 md:gap-5 text-base md:text-lg font-semibold pl-4 pr-2'>
                                            <h1>Type</h1>
                                            <h1>Priority</h1>
                                        </div>

                                        <hr className='border border-gray-400' />

                                        <div className='grid grid-cols-2 gap-10 md:gap-5 text-md md:text-lg pl-4 pr-2'>
                                            <p className='font-sans'>{ factory_type?.split( 'TypePriority' )[ 1 ]?.split( /(?=[A-Z])/ )[ 0 ]?.split( /\d/ )[ 0 ] }</p>

                                            <p className='font-sans'>{ factory_type?.split( 'TypePriority' )[ 1 ]?.split( /(?=[A-Z])/ )[ 0 ]?.slice( -1 ) }</p>

                                        </div>
                                        <hr />
                                        <div className='grid grid-cols-4 gap-5 text-lg pl-4 pr-2'>
                                            <p className='font-sans'>{ factory_type?.split( 'TypePriority' )[ 1 ]?.split( /(?=[A-Z])/ )[ 1 ]?.split( /\d/ )[ 0 ] }</p>
                                            <p className='font-sans'>{ factory_type?.split( 'TypePriority' )[ 1 ]?.split( /(?=[A-Z])/ )[ 1 ]?.slice( -1 ) }</p>
                                        </div>

                                    </div>
                                </div>

                                {/* No. of Employees section----------------------------- */ }
                                <div className="flex w-full ">
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-2/6 md:w-1/4 lg:w-1/6 mr-0">
                                        <h1 className='text-sm md:text-lg font-semibold text-[#1B3A57]'>No. of Employees:</h1>
                                    </div>
                                    <div className="divider divider-horizontal ml-5 md:ml-0 mr-0"></div>

                                    <div className="flex flex-row justify-between gap-2 md:grid md:flex-grow card rounded-sm pl-4 pr-2 py-2 w-10/12 ml-0">
                                        <div className='flex flex-col md:grid grid-cols-3 gap-2 md:gap-5 text-sm md:text-xl font-semibold pl-4 pr-2'>
                                            <h1>Management</h1>
                                            <h1>Employee Male</h1>
                                            <h1>Employee Female</h1>

                                        </div>
                                        <hr className='border border-gray-400' />

                                        <div className='grid grid-cols-4 gap-5 text-lg pl-4 pr-2 py-2'>
                                            <p className='font-sans'>{ no_of_employees?.split( /\D+/ ) }</p>

                                        </div>

                                    </div>
                                </div>


                                {/* No of Machines Section---------------------------------- */ }
                                <div className="flex w-full bg-[#EFEFEF]">
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-2/6 md:w-1/4 lg:w-1/6 mr-0">
                                        <h1 className='text-sm md:text-lg font-semibold text-[#1B3A57]'>No of Machines:</h1>
                                    </div>
                                    <div className="divider divider-horizontal ml-5 md:ml-0 mr-0"></div>
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-10/12 ml-0">
                                        <p className='font-sans'>{ no_of_machines }</p>
                                    </div>
                                </div>

                                {/* Production Capacity section----------------------------- */ }
                                <div className="flex w-full ">
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-2/6 md:w-1/4 lg:w-1/6 mr-0">
                                        <h1 className='text-sm md:text-lg font-semibold text-[#1B3A57]'>Production Capacity:</h1>
                                    </div>
                                    <div className="divider divider-horizontal ml-4 md:ml-0 mr-0"></div>
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-10/12 ml-0">
                                        <p className='font-sans'>{ production_capacity }</p>
                                    </div>
                                </div>

                                {/* Certifications Section---------------------------------- */ }
                                <div className="flex w-full bg-[#EFEFEF]">
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-2/6 md:w-1/4 lg:w-1/6 mr-0">
                                        <h1 className='text-sm md:text-lg font-semibold text-[#1B3A57]'>Certifications:</h1>
                                    </div>
                                    <div className="divider divider-horizontal ml-0 mr-0"></div>


                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-10/12 ml-0">
                                        <div className='flex flex-col md:grid grid-cols-4 gap-2 md:gap-5 text-base md:text-lg font-semibold pl-4 pr-2'>
                                            <h1>ISO</h1>
                                            <h1>SRM</h1>
                                            <h1>SA</h1>
                                            <h1>Others</h1>
                                            {/* <h1>{ certifications }</h1> */ }

                                        </div>

                                    </div>
                                    {/* <div className="grid flex-grow card bg-[#EFEFEF] rounded-sm pl-4 pr-2 py-2 w-10/12 ml-0">
                                        <div className='font-sans grid'>
                                            <h1>{ certifications }</h1>
                                            <h1>{ certifications }</h1>
                                        </div>
                                    </div> */}
                                </div>

                                {/* Principal Exportable Product section----------------------------- */ }
                                <div className="flex w-full ">
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-2/6 md:w-1/4 lg:w-1/6 mr-0">
                                        <h1 className='text-base md:text-lg font-semibold text-[#1B3A57]'>Principal Exportable Product:</h1>
                                    </div>
                                    <div className="divider divider-horizontal ml-3 md:ml-0 mr-0"></div>
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-10/12 ml-0">
                                        <p className='font-sans'>{ principal_exportable_product }</p>
                                    </div>
                                </div>

                                {/* Annual Turnover Section---------------------------------- */ }
                                <div className="flex w-full bg-[#EFEFEF]">
                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-2/6 md:w-1/4 lg:w-1/6 mr-0">
                                        <h1 className='text-base md:text-lg font-semibold text-[#1B3A57]'>Annual Turnover:</h1>
                                    </div>
                                    <div className="divider divider-horizontal ml-4 md:ml-0 mr-0"></div>

                                    <div className="grid flex-grow card rounded-sm pl-4 pr-2 py-2 w-10/12 ml-0">
                                        <div className='grid grid-cols-2 md:grid-cols-4 gap-5 text-base md:text-lg font-semibold pl-4 pr-2'>
                                            <h1>Year</h1>
                                            <h1>US $</h1>

                                        </div>
                                        <hr className='border border-gray-400' />

                                        <div className='grid grid-cols-2 md:grid-cols-4 gap-5 text-lg pl-4 pr-2 py-2'>
                                            <p className='font-sans'>{ annual_turnover?.split( '$' )[ 1 ]?.split( ':' )[ 0 ] }</p>
                                            <p className='font-sans'>$ { annual_turnover?.split( '$' )[ 2 ] }</p>


                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    );
};

export default MemberDetails;