import React from 'react';
import Exp from '../../../Assets/heroIcon/image 2.png'
import Complaint from '../../../Assets/heroIcon/image 5.png'
import Competitive from '../../../Assets/heroIcon/image 6.png';
import yarnleft from '../../../Assets/heroIcon/yarnleft.png'
import yarnright from '../../../Assets/heroIcon/yarnright.png'

import './WhyBangladesh.css'



const WhyBangladesh = () => {


    return (
        <div data-aos="fade-up" data-aos-duration="1000" className='w-11/12 mx-auto my-20 md:my-28 text-center '>

            <h1 className='text-3xl md:text-4xl font-bold uppercase text-secondary my-3'>Why Bangladesh</h1>
            <div className='w-5/6 md:w-3/4 mx-auto'>
                <p className='text-justify font-sans'>There are innumerable people who haven't yet visited Bangladesh but felt its warmth by the virtue of world-class apparel made by the country. Garments with “Made in Bangladesh” tag reach people of all ages in more than <span className=''>150 countries</span>, essentially providing with their second skin, keeping them warm during winter, comfortable in summer and trendy in autumn. Choose Bangladesh as your sole green and compliant hub for sourcing.</p>

                <div data-aos="fade-up" data-aos-duration="1000"
                    data-aos-anchor-placement="top-bottom" className='mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:relative'>
                    <img src={ yarnleft } alt="" className='absolute -left-28 lg:-left-[184px] -bottom-6 lg:-bottom-20 w-1/5' />
                    <img src={ yarnright } alt="" className='absolute -top-24 -right-28 lg:-right-40 w-1/6 ' />
                    <div className='cardShadow rounded-md py-7 px-6'>
                        <img src={ Exp } alt="" className='mx-auto' />
                        <h1 className='py-4 text-xl font-semibold'>EXPERIENCE</h1>
                        <p>35+ years of experience in apparel manufacturing, With 4000+ factories Bangladesh is serving all major global fashion brands.</p>
                    </div>
                    <div className='cardShadow rounded-md py-7 px-6'>
                        <img src={ Complaint } alt="" className='mx-auto' />
                        <h1 className='py-4 text-xl font-semibold'>COMPLIANT</h1>
                        <p>All factories maintain international safety and compliance, and assessed in accordance with BSCI, WRAP, SMETA, ICS, Higg index, GTW, RTM, Join Life, ZDHC, Blue Sign, ISO, OCS, GOTS, Oekotex.</p>
                    </div>
                    <div className='cardShadow rounded-md py-7 px-6'>
                        <img src={ Competitive } alt="" className='mx-auto' />
                        <h1 className='py-4 text-xl font-semibold'>COMPETITIVE</h1>
                        <p>Duty-free Market Access to EU, Experienced Labour Force, and International Quality.</p>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default WhyBangladesh;